import React, { useRef, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import * as style from './Gallery.module.scss';
import MaxWidth from '../MaxWidth/MaxWidth';
import Arrow from '../Arrow/Arrow';
import StickToTop from '../StickToTop/StickToTop';

export default function Gallery({ data: { allSanityGallery: data } }) {
  const BackLink = ({ cat }) => {
    const linkRef = useRef(null);

    useEffect(() => {
      linkRef.current.style.opacity = 1;
    });

    return (
      <Link
        ref={linkRef}
        to={`/folio/${cat.slug.current}`}
        className={style.back}
        style={{ opacity: 0, transition: 'opacity 1s' }}
      >
        <Arrow />
        {cat.category_name.toLowerCase()}
      </Link>
    );
  };

  const gallery = data.nodes[0];
  const { gallery_name: name, gallery_text: text, gallery_images: images, category } = gallery;

  return (
    <>
      <Helmet>
        <title>{name} | Ottone Photography</title>
      </Helmet>
      <MaxWidth>
        <div className={style.galleryWrapper}>
          <div className={style.backContainer}>
            <StickToTop>
              <BackLink cat={category} />
            </StickToTop>
          </div>
          <div className={style.galleryContainer}>
            <div className={style.textContainer}>
              <h1 className={style.title}>{name}</h1>
              <p className={style.text}>{text}</p>
            </div>
            <div className={style.images}>
              {images.map((image, i) => {
                return (
                  <Img fluid={image.image.asset.fluid} alt={image.alt} className={style.image} key={`galImg${i}`} />
                );
              })}
            </div>
          </div>
        </div>
      </MaxWidth>
    </>
  );
}

export const query = graphql`
  query($id: String!) {
    allSanityGallery(filter: { id: { eq: $id } }) {
      nodes {
        gallery_name
        gallery_text
        gallery_images {
          alt
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        category {
          slug {
            current
          }
          category_name
        }
      }
    }
  }
`;
