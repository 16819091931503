import React from 'react';
import * as style from './Arrow.module.scss';

export default function Arrow() {
  return (
    <svg className={style.arrow} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="27" />
      <path d="M29 9.9375H13.4V4L3 13.5L13.4 23V17.0625H29" stroke="var(--white)" strokeWidth="3" />
    </svg>
  );
}
