import React from 'react';
import * as style from './StickToTop.module.scss';

export default function StickToTop({ children }) {
  return (
    <div className={style.container}>
      <div className={style.fixed}>{children}</div>
    </div>
  );
}
